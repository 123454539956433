import { NodeFormat } from '../nodeFormat';
import type { HTMLConverter } from '../types';
import { LexicalOptions } from '../serializeLexical';

export const TextHTMLConverter: HTMLConverter<any> = {
  converter({ node, options }: { node: any; options?: LexicalOptions }) {
    let text = <>{node.text}</>;

    if (node.format & NodeFormat.IS_BOLD) {
      text = <strong>{text}</strong>;
    }
    if (node.format & NodeFormat.IS_ITALIC) {
      text = <em>{text}</em>;
    }
    if (node.format & NodeFormat.IS_STRIKETHROUGH) {
      text = <span style={{ textDecoration: 'line-through' }}>{text}</span>;
    }
    if (node.format & NodeFormat.IS_UNDERLINE) {
      text = <span style={{ textDecoration: 'underline' }}>{text}</span>;
    }
    if (node.format & NodeFormat.IS_CODE) {
      text = <div>{text}</div>;
    }
    if (node.format & NodeFormat.IS_SUBSCRIPT) {
      const color = options?.altTextColor ?? 'var(--purple-primary)';
      text = (
        <span
          style={{
            color,
            background: color,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {text}
        </span>
      );
    }
    if (node.format & NodeFormat.IS_SUPERSCRIPT) {
      const color = options?.altTextColor ?? 'var(--purple-primary)';
      text = (
        <span
          style={{
            color,
            background: color,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {text}
        </span>
      );
    }

    return text;
  },
  nodeTypes: ['text'],
};
