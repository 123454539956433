import type { HTMLConverter } from '../types';

import { convertLexicalNodesToReactNode } from '../serializeLexical';

export const QuoteHTMLConverter: HTMLConverter<any> = {
  converter({ converters, node, parent, options }) {
    const childrenText = convertLexicalNodesToReactNode({
      converters,
      lexicalNodes: node.children,
      parent: {
        ...node,
        parent,
      },
      options,
    });

    return <blockquote>{childrenText}</blockquote>;
  },
  nodeTypes: ['quote'],
};
