import { IImageBlock } from '@/src/payload-types';
import React from 'react';
import styles from './Blocks.module.scss';
import { georgia } from '@/src/fonts';
import Image from '@/src/components/Image';

const BlogImageBlock: React.FC<IImageBlock> = ({ image, caption }) => {
  return (
    <div className={styles.container}>
      {caption && <p className={georgia.className + ' ' + styles.caption}>{caption}</p>}
      <div className={styles.imageContainer}>
        {image && typeof image !== 'string' && image?.url && (
          <Image
            width={image?.width || 0}
            height={image?.height || 0}
            src={image?.url}
            alt={image?.alt}
            sizes="100%"
          />
        )}
      </div>
    </div>
  );
};

export default BlogImageBlock;
