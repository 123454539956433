'use client';
import { IBannerRelation } from '@/src/payload-types';
import { LexicalOptions, serializeLexical } from '@/src/payload/lexical/serializeLexical';
import styles from './Banner.module.scss';
import { useEffect, useRef, useState } from 'react';
import { MEDIUM_VIEWPORT_MIN } from '@/src/styles/responsive-breakpoints';
import Image from '@/src/components/Image';

const Banner = (props: IBannerRelation & { className?: string; style?: React.CSSProperties }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isMobile, setMobile] = useState(false);
  const [halfImgStyle, sethalfImgStyle] = useState<React.CSSProperties>({
    height: 0,
  });

  useEffect(() => {
    const resizeHalfImg = () => {
      const isMobile = window.innerWidth < MEDIUM_VIEWPORT_MIN;
      ref.current &&
        sethalfImgStyle({ ...halfImgStyle, height: isMobile ? 'auto' : ref.current?.clientHeight });
      setMobile(isMobile);
    };

    resizeHalfImg();
    window.addEventListener('resize', resizeHalfImg);
    return () => {
      window.removeEventListener('resize', resizeHalfImg);
    };
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { banner, style, className } = props;
  if (banner && typeof banner !== 'string') {
    const { content, illustration, overlay } = banner;
    const stackedActive = isMobile && illustration?.halfConfig?.mobileBehavior === 'stacked';
    const bgOverlayActive =
      (isMobile &&
        illustration?.backgroundOverlay &&
        illustration?.halfConfig?.mobileBehavior === 'background') ||
      (illustration?.backgroundOverlay && illustration?.illustrationType === 'background');

    const renderBackgroundIllustration = () => {
      return (
        illustration?.imageAsset &&
        typeof illustration?.imageAsset !== 'string' &&
        illustration?.imageAsset?.url &&
        illustration.illustrationType === 'background' && (
          <Image
            src={illustration?.imageAsset.url}
            alt={illustration?.imageAsset.alt}
            width={illustration?.imageAsset.width || 0}
            height={illustration?.imageAsset.height || 0}
            sizes="100vw"
            className={`${styles.imgBackground}`}
          />
        )
      );
    };

    const renderHalfIllustration = () => {
      return (
        illustration?.imageAsset &&
        typeof illustration?.imageAsset !== 'string' &&
        illustration?.imageAsset?.url &&
        illustration?.illustrationType === 'half' && (
          <Image
            src={illustration?.imageAsset.url}
            alt={illustration?.imageAsset.alt}
            width={illustration?.imageAsset.width || 0}
            height={illustration?.imageAsset.height || 0}
            sizes="50vw" // TODO
            className={`${styles.halfImg} ${bgOverlayActive ? styles.halfImgBackground : ''}`}
            style={{
              height: halfImgStyle?.height,
              left:
                content?.alignment === 'leftLeft' && !isMobile
                  ? `calc(50% + ${illustration?.halfConfig?.bleedPadding ? '0px' : '32px'})`
                  : 'unset',
              right:
                content?.alignment === 'rightLeft' && !isMobile
                  ? `calc(50% + ${illustration?.halfConfig?.bleedPadding ? '0px' : '32px'})`
                  : 'unset',
              display: illustration?.halfConfig?.mobileBehavior === 'hidden' ? 'none' : 'block',
              position: stackedActive ? 'static' : 'absolute',
              width: stackedActive ? '100vw' : 'unset',
            }}
          />
        )
      );
    };

    const lexicalOptions: LexicalOptions = {
      altTextColor: content?.altTextColor,
      primaryColor: 'var(--white-primary)',
    };
    const contentClasses = `${styles.contentContainer} 
    ${content?.contentType === 'cta' ? styles.cta : content?.contentType === 'highlight' ? styles.highlight : ''}`;

    return (
      <div
        className={`${className || ''} ${styles.container} ${bgOverlayActive ? styles.containerWithOverlay : ''} banner`}
        style={{
          ...style,
          backgroundColor: illustration?.baseBackground ?? undefined,
          padding: stackedActive ? '48px 0 0 0' : isMobile && !bgOverlayActive ? '32px 0' : '80px 0',
          overflow: overlay ? 'visible' : 'hidden',
        }}
        ref={ref}
      >
        {renderBackgroundIllustration()}
        {bgOverlayActive && <BgOverlay color={illustration?.backgroundOverlay || ''} />}
        {overlay && typeof overlay !== 'string' && overlay.url && (
          <Image
            src={overlay?.url}
            alt={overlay.alt}
            width={overlay.width || 0}
            height={overlay.height || 0}
            sizes="10vw" // TODO
            className={styles.overlay}
          />
        )}
        {content?.width == 'full' && (
          <>
            <div className={`${styles.full} ${contentClasses}`}>
              {content?.richText && serializeLexical(content?.richText, lexicalOptions)}
            </div>
          </>
        )}
        {content?.width == 'singleColumn' && (
          <>
            <div className={`${styles.half}`}>
              <div
                className={`${styles.singleColumn} ${contentClasses}`}
                style={{
                  textAlign: content?.textAlignment || 'left',
                  margin: 'unset',
                }}
              >
                {content?.richText && serializeLexical(content?.richText, lexicalOptions)}
              </div>
            </div>
          </>
        )}
        {content?.width == 'half' && (
          <>
            <div
              className={`${styles.half}`}
              style={
                {
                  alignItems: content?.alignment === 'leftLeft' ? 'flex-start' : '',
                  gap: stackedActive ? '48px' : illustration?.halfConfig?.bleedPadding ? 0 : '32px',
                  flexDirection: stackedActive
                    ? 'column'
                    : content?.alignment === 'leftLeft'
                      ? 'row'
                      : content?.alignment === 'rightLeft'
                        ? 'row-reverse'
                        : '',
                } as React.CSSProperties
              }
            >
              <div className={`${contentClasses} ${styles.halfContent}`}>
                {content?.richText && serializeLexical(content?.richText, lexicalOptions)}
              </div>
              {renderHalfIllustration()}
            </div>
          </>
        )}
      </div>
    );
  }
  return null;
};

const BgOverlay = ({ color }: { color: string }) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: color || 'rgba(0, 0, 0, 0.5)',
      zIndex: 1,
      pointerEvents: 'none',
    }}
  />
);

export default Banner;
