import type { HTMLConverter } from '../types';

import { convertLexicalNodesToReactNode } from '../serializeLexical';
import { SerializedLinkNode } from '@payloadcms/richtext-lexical';
import Link from 'next/link';
import styles from './Converters.module.scss';

export const LinkHTMLConverter: HTMLConverter<SerializedLinkNode> = {
  converter({ converters, node, parent, options }) {
    const childrenText = convertLexicalNodesToReactNode({
      converters,
      lexicalNodes: node.children,
      parent: {
        ...node,
        parent,
      },
      options,
    });

    let href = '';
    if (node.fields.linkType === 'custom') {
      href = node.fields.url || '';
    } else {
      if (typeof node.fields.doc?.value === 'string') {
        href = node.fields.doc?.value;
      } else {
        href = node.fields.doc?.value?.id ?? '';
      }
    }

    return (
      <Link
        href={href}
        passHref
        rel={node.fields.newTab ? ' rel="noopener noreferrer"' : ''}
        className={styles.link}
        target={node.fields.newTab ? '_blank' : '_self'}
      >
        {childrenText}
      </Link>
    );
  },
  nodeTypes: ['link', 'autolink'],
};
