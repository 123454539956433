import type { FC } from 'react';
import NextImage from 'next/image';
import type { ImageProps } from 'next/image';

const Image: FC<ImageProps> = ({ quality, src, ...props }) => {
  let devSafeSrc =
    process.env.NODE_ENV === 'development' || (typeof src === 'string' && !src?.includes('http'))
      ? `${process.env.NEXT_PUBLIC_API_URL}/api/media/file/${src}`
      : src; // necessary for development - for some reason payload is not applying the base url
  return <NextImage {...props} src={devSafeSrc} quality={quality ?? 100} />;
};

export default Image;
